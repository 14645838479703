import React from 'react'
import Logo from '../../assets/IMG_5323.PNG'
import Coin from '../../assets/icons/coin.svg'
import Dex from '../../assets/icons/dex.svg'
import Geko from '../../assets/icons/geko.svg'

const NavBar = () => {
    return (
        <div className="main-nav-bar">

            <div className="container">
                <div className="logos">
                    <a href="#"><img src={Logo} alt="" /></a>
                    <a href="https://pizza-nft.com/" className="back-button">Back to Home</a>
                </div>
            </div>
        </div>
    )
}

export default NavBar
