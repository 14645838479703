import React, { useEffect, useState } from 'react'


import NavBar from '../components/navbar/NavBar'
import Footer from '../components/footer/Footer'



import ContactImage from '../assets/contact.png'


const Home = () => {
    return (
        <>


            <NavBar />

            <div className="main-road-map-cont">
                <div className="container">

                </div>
            </div>

            <div className="contact-image">
                <div className="container">
                    <img src={ContactImage} alt="" className='contact-imge-container' />
                    <div className="emails">
                        <div className="email-container">
                            <a href="mailto:info@pizza-nft.com">info@pizza-nft.com</a>
                            <a href="mailto:admin@pizza-nft.com">admin@pizza-nft.com</a>
                            <a href="mailto:techsupprt@pizza-nft.com">techsupprt@pizza-nft.com</a>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />


        </>
    )
}

export default Home
